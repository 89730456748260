import React from 'react';

const PageNotFound = () => {
	return (
		<div className='page' >
			<p>Page not found</p>
			<p>Wrong URL!</p>
		</div>
	);
}

export default PageNotFound;